import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

// MS
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
// Config
import { msalConfig } from "./config/authConfig";
// App
import App from "./app/App";
// Styles
import "./index.css";

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));

// El router remplaza el hash en la url, el cual es requerido por msal para autenticar en el popUp
if (window.location.hash !== "") {
} else {
   root.render(
      <MsalProvider instance={msalInstance}>
         <App instance={msalInstance} />
      </MsalProvider>
   );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
