import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//Mui components
import { Box, Typography, MobileStepper, Paper } from "@mui/material/";
// Componentes
import MainEmailForm from "./MainEmailForm";
import EmailSended from "./EmailSended";
import AltEmailForm from "./AltEmailForm";

// Context
const steps = ["Correo institucional", "Correo alternativo", "Enviado"];

export default function StepperForm() {
   const navigate = useNavigate();

   const [form, setForm] = useState("");
   const [activeStep, setActiveStep] = useState(0);
   const maxSteps = steps.length;

   /**
    * @description función para cambiar el componente que será renderizado dentro del stepper
    * @param {*} step - número de paso
    * @returns 
    */
   function renderStepContent(step) {
      switch (step) {
         case 0:
            return <MainEmailForm handleNext={(data) => handleNext(data)}  goToLastStep={goToLastStep}/>;
         case 1:
            return <AltEmailForm handleNext={(data) => handleNext(data)} formData={form} />;
         case 2:
            return <EmailSended handleNext={goToInsertCodePage} handleStart={handleStart} formData={form}/>;
         default:
            return <MainEmailForm handleNext={(data) => handleNext(data)}  goToLastStep={goToLastStep}/>;
      }
   }

   /**
    * @description función para realizar el cambio al siguiente paso
    * @param {*} data - email, email alternativo
    */
   const handleNext = (data) => {
      if (data) setForm(data);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
   };

   /**
    * @description activa el inicio del stepper
    */
   const handleStart = () => {
      setForm("");
      setActiveStep(0);
   };

   /**
    * @description permite redirección al último pasoo del stepper
    */
   const goToLastStep = (value) => {
      if (value) setActiveStep(2);
   };

   /**
    * @description redirecciona al usuario a la página de restablecimiento
    */
   const goToInsertCodePage = () => {
      navigate("/restablecer-contrasena");
   };

   return (
      <Box
         sx={{
            width: "100%",
            height: "72vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
         }}
      >
         <Paper
            square
            elevation={0}
            sx={{
               display: "flex",
               alignItems: "center",
               height: 50,
               pl: 2,
               bgcolor: "background.default",
            }}
         >
            <Typography>{steps[activeStep].label}</Typography>
         </Paper>
         <Box
            sx={{
               height: "100%",
               maxWidth: 550,
               width: "100%",
               p: 2,
               display: "flex",
               flexDirection: "column",
               alignItems: "center",
               justifyContent: "center",
            }}
         >
            {renderStepContent(activeStep)}
            <MobileStepper
               variant="dots"
               steps={maxSteps}
               position="static"
               activeStep={activeStep}
            />
         </Box>
      </Box>
   );
}
