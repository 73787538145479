import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { Container } from "@mui/material";

import MyAccount from "../pages/MyAccount";
import InstitutionalAccounts from "../pages/InstitutionalAccounts";
import PrivateNavBar from "../components/navBar/PrivateNavBar";

// Styles
import "./styles/PrivateLayout.css";

export default function PrivateLayout() {
   const routes = [
      {
         path: "/cuentas-institucionales",
         component: <InstitutionalAccounts />,
         name: "Cuentas institucionales",
      },
      {
         path: "/mi-cuenta",
         component: <MyAccount />,
         name: "Mi cuenta",
      },
      {
         path: "*",
         component: <InstitutionalAccounts replace to="/cuentas-institucionales" />,
         name: "Cuentas institucionales",
      },
   ];

   return (
      <Fragment>
         <Router>
            <PrivateNavBar />
            <Container maxWidth={false} disableGutters className="">
               <Routes>
                  {routes.map((route) => (
                     <Route key={route.path} path={route.path} element={route.component} />
                  ))}
               </Routes>
            </Container>
         </Router>
      </Fragment>
   );
}
