import React from "react";

// Context
import { AppProvider } from "../context/GlobalContext";
// Containers
import AppContainer from "../containers/AppContainer";
// Theme
import theme from "./theme";
// Mui
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material/";
// Styles
import "./App.css";

function App(props) {
   const { instance } = props;
   
   return (
      <ThemeProvider theme={theme}>
         <CssBaseline />
         <AppProvider instance={instance}>
            <AppContainer />
         </AppProvider>
      </ThemeProvider>
   );
}

export default App;
