import React from "react";

//MUI components
import { Alert } from "@mui/material/";
import Snackbar from "@mui/material/Snackbar";

export default function CustomAlert(props) {
   const { open, severity, message, handleCloseAlert } = props;

   return (
      <React.Fragment>
         <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={open}
            autoHideDuration={3500}
            onClose={() => handleCloseAlert()}
         >
            <Alert
               elevation={6}
               variant="filled"
               onClose={() => handleCloseAlert()}
               severity={severity || "success"}
            >
               {message}
            </Alert>
         </Snackbar>
      </React.Fragment>
   );
}
