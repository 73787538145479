// Core imports
import React, { Fragment } from "react";

// Mui components
import { Grid } from "@mui/material";
//Styles
import "./styles/PasswordReset.css";
//
import PublicNavBar from "../components/navBar/PublicNavBar";
import StepperForm from "../components/passwordReset/StepperForm";

export default function PasswordReset() {
   return (
      <Fragment>
         <PublicNavBar />

         <Grid container justifyContent="center" alignItems="stretch">
            <StepperForm></StepperForm>
         </Grid>
      </Fragment>
   );
}
