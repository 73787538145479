export const msalConfig = {
   auth: {
      clientId: process.env.REACT_APP_CLIENT_ID,
      tenant: process.env.REACT_APP_AUTHORITY,
      redirectUri: process.env.REACT_APP_REDIRECT_URI,
   },
   cache: {
      cacheLocation: "localStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
   },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
   scopes: ["User.Read"],
};

export const silentRequest = {
   scopes: ["User.Read"],
   forceRefresh: false,
};
