let config = {
   // imagen mostrada en la portada
   banner_image:
      "https://intevolution.ai/_next/image?url=%2Fimg%2Flogo-intevolution-landscape.svg&w=256&q=75",
   // enlace de "Regresar"
   back_link: "https://intevolution.ai/",
   /* personalización del botón */
   // color del botón
   button_color: "#005da6",
   // color del texto
   button_text_color: "#FFFFFF",
   // color de fondo
   background_color: "#EFF3F5",
   // color del botón
   card_background: "#00Aca6",
};
export default config;
