import React from "react";
//MUI components
import { Dialog, DialogContent, CircularProgress } from "@mui/material";

// styles
import "./styles/LoadingDialog.css";

export default function LoadingDialog(props) {
   const { open, loadingMessage } = props;

   return (
      <Dialog open={open}>
         <DialogContent>
            <div className="dialog-content">
               <CircularProgress color="primary" />
               <p>{loadingMessage}</p>
               <p>Espere un momento por favor.</p>
            </div>
         </DialogContent>
      </Dialog>
   );
}
