import React, { Fragment } from "react";

// MS
import {
   AuthenticatedTemplate,
   UnauthenticatedTemplate,
   useIsAuthenticated,
} from "@azure/msal-react";

// Context
import AppProvider from "../context/GlobalContext";

// Layouts
import PublicLayout from "../layouts/PublicLayout";
import PrivateLayout from "../layouts/PrivateLayout";

export default function AppContainer() {
   let isAuthenticatedMSAL = useIsAuthenticated();

   return (
      <Fragment>
         <AuthenticatedTemplate>
            <PrivateLayout />
         </AuthenticatedTemplate>
         <UnauthenticatedTemplate>
            <PublicLayout />
         </UnauthenticatedTemplate>
      </Fragment>
   );
}
