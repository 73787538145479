import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

// Mui
import { Container } from "@mui/material";

// Pages
import HomePage from "../pages/HomePage";
import PasswordReset from "../pages/PasswordReset";
import AccountForm from "../pages/AccountForm";
import AccountInfo from "../pages/AccountInfo";
import PasswordResetCode from "../pages/PasswordResetCode";

// Styles
import "./styles/PublicLayout.css";

const routes = [
   {
      path: "/",
      component: <HomePage />,
      name: "Inicio",
   },
   {
      path: "/restablecer",
      component: <PasswordReset />,
      name: "Restablecer contraseña",
   },
   {
      path: "/informacion",
      component: <AccountForm />,
      name: "Información de la cuenta",
   },
   {
      path: "/cuenta",
      component: <AccountInfo />,
      name: "Información de la cuenta",
   },
   {
      path: "/restablecer-contrasena",
      component: <PasswordResetCode />,
      name: "Restablecer contraseña - Ingresar código",
   },

   {
      path: "*",
      component: <Navigate replace to="/" />,
      name: "Inicio",
   },
];

export default function PublicLayout() {
   return (
      <Router>
         <Container maxWidth={false} disableGutters className="content-container">
            <Routes>
               {routes.map((route) => (
                  <Route key={route.path} path={route.path} element={route.component} />
               ))}
            </Routes>
         </Container>
      </Router>
   );
}
