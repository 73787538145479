// Core imports
import React, { Fragment } from "react";

// Mui components
import { Grid } from "@mui/material";
//Styles
import "./styles/PasswordReset.css";
// Componentes
import PublicNavBar from "../components/navBar/PublicNavBar";
import MainForm from "../components/accountForm/MainForm";

export default function AccountForm() {
   return (
      <Fragment>
         <PublicNavBar />

         <Grid container justifyContent="center" alignItems="stretch">
            <MainForm></MainForm>
         </Grid>
      </Fragment>
   );
}
