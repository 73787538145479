import React, { useState, useContext } from "react";

// Images
import icon from "../../assets/images/key.png";
//External Libraries
import Swal from "sweetalert2"; 
// Mui components
import { Box, Button, TextField, CardMedia } from "@mui/material";
// Context
import AppProvider from "../../context/GlobalContext";

//Style components
const headerStyle = { color: "#000000", fontSize: "1.1rem" };
const keyIconStyle = { height: 40, width: 45 };
const formBox = {
   borderRadius: "4px",
   boxShadow: "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
   padding: "15px 22px",
};

export default function MainEmailForm(props) {
   let { handleNext, goToLastStep } = props;

   const { getAlternativeEmail, sendResetPasswordMail } = useContext(AppProvider);

   const [form, setForm] = useState({
      email: "",
      // si el email es invalido, el valor será true, 
      validateMail: false,
      // si el email es invalido, el valor será true, de lo contrario, pasa a false y deshabilitará el botón "siguiente"
      buttonState: true,
      // valida si se muestra el mensaje de error
      propError: false,
      messageError: "",
      error: false,
   });


   /**
    * @description Funcion para manejar los cambios en el formulario
    * @param {*} targetName - nombre del campo
    * @param {*} targetValue - valor
    */
   const handleChange = (targetName, targetValue) => {
      let formCopy = JSON.parse(JSON.stringify(form));

      // remove spaces
      targetValue.replace(/\s/g, "");

      formCopy.email = targetValue;
      // Expresion regular para validar que lo ingresado corresponde al formato de email con el dominio @senl.gob.mx
      const regExIsDomainValid = /^[a-zA-Z0-9._+-]+@senl\.gob\.mx/i;
      //Validación de email
      if (regExIsDomainValid.test(targetValue)) {
         //Si el correo es valido, quitamos los errores y habilitamos el botón
         formCopy.buttonState = false;
         formCopy.buttonError = false;
         formCopy.propError = false;
         formCopy.messageError = "";
      } else {
         // Mostramos el error y deshabilitamos el botón
         formCopy.buttonState = true;
         formCopy.buttonError = true;
         formCopy.propError = true;
         formCopy.messageError = "Ingresa un correo válido";
      }
      setForm(formCopy);
   };

   /**
    * @description Función para validar si la cuenta institucional ya tiene registrado un correo alternativo.
    * Si no cuenta con uno, redirige al usuario al formulario para agregarlo.
    * Si existe, muestra un mensaje indicando el correo y redirige al último paso.
    */
   async function handleAccountEmail() {
      let data = {
         email: form.email,
      };
      let response = await getAlternativeEmail(data);

      if (response.code) {
         if (response.email) {
            Swal.fire({
               icon: "info",
               title: "Esta cuenta ya contiene un correo alternativo, previamente registrado.",
               html: `Se enviará el mensaje al correo <b>${response.email}</b>. <br /><br /> En caso de desear cambiar su correo alternativo contactate al <a href='mailto:someone@example.com'>administrador de correos</a>.`,
               background: "#fff",
               width: 600,
               confirmButtonColor: "#00Aca6",
               allowOutsideClick: false,
            });

            let res = await sendResetPasswordMail({ email: form.email });

            // redirigimos al usuario al último paso "revisa tu correo"
            if (res) goToLastStep(true);

         }else{
            // redirigimos al usuario al formulario de correo alternativo
            handleNext(form.email);
         }
      }
   }
   
   return (
      <div>
         <Box bgcolor="background.paper" style={formBox}>
            <div className="form-container--text">
               <div style={{ display: "flex", justifyContent: "center" }}>
                  <CardMedia component="img" src={icon} style={keyIconStyle} />
               </div>
               <h2>¿Olvidaste tu contraseña?</h2>
               <p style={headerStyle}>
                  Te enviaremos las instrucciones a tu correo para que puedas restaurarla.
               </p>
            </div>

            <TextField
               color="primary"
               label="Ingresa tu correo institucional"
               placeholder=" Ejemplo: correo@onmicrosoft.com"
               fullWidth
               required
               error={form.propError}
               variant="outlined"
               value={form.email}
               onChange={(event) => handleChange(event.target.name, event.target.value)}
               helperText={form.messageError}
            />
            <div className="justify-content--end">
               <Button
                  variant="contained"
                  disabled={form.buttonState}
                  size="large"
                  onClick={handleAccountEmail}
               >
                  Siguiente
               </Button>
            </div>
         </Box>
      </div>
   );
}
