import React, { useContext } from "react";
import { Link } from "react-router-dom";

// Mui components
import { Grid, Button } from "@mui/material";
// Images
import heroImage from "../assets/images/home.png";
import logo from "../assets/images/SENL.png";

// Context
import AppProvider from "../context/GlobalContext";

//External Libraries
import { useMsal } from "@azure/msal-react"; // https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-react
import { Image } from "mui-image"; // https://www.npmjs.com/package/mui-image
import Swal from "sweetalert2"; //https://sweetalert2.github.io

//Styles
import "./styles/HomePage.css";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function HomePage() {
   const context = useContext(AppProvider);
   const { updateContextAttribute, fetchUserData } = context;
   const { instance } = useMsal();

   /**
    * @description función para el manejo del login
    * @returns 
    */
   const handleLogin = async () => {
      // colocamos un loader
      updateContextAttribute("loadingDialog", true);
      updateContextAttribute("loadingMessage", "Validando credenciales...");
      try {
         // activamos el popup para realizar el inicio de sesión con el directorio activo
         let res = await instance.loginPopup();
         if (!res.accessToken) {
            Swal.fire({
               icon: "error",
               title: "Ocurrió un error...",
               text: "No se pudo iniciar sesión",
            });
            return;
         }
         // colocamos la cuenta activa en la instancia de msal
         instance.setActiveAccount(instance.getAllAccounts()[0]);
         localStorage.setItem("token", res.idToken);
         localStorage.setItem("userData", JSON.stringify(res.account));

         updateContextAttribute("loadingDialog", false);
         updateContextAttribute("loadingMessage", "");
         // obtenemos los datos del usuario que inicio sesión
         await fetchUserData(res.account.username);
      } catch (error) {
         updateContextAttribute("loadingDialog", false);
         updateContextAttribute("loadingMessage", "");
      }
   };

   const theme = useTheme();
   const smallToMid = useMediaQuery(theme.breakpoints.between("sm", "md"));
   const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));

   return (
      <Grid container className="public-container">
         <Grid item xs={12} sm={12} md={4} xl={5}>
            <Image src={heroImage} errorIcon={null} duration={250} className="hero-image" />
         </Grid>
         <Grid item xs={12} sm={12} md={8} xl={7} className="home__container">
            <Grid container className="logo__container">
               <Image
                  src={logo}
                  errorIcon={null}
                  duration={250}
                  id="logo"
                  width={lessThanSmall ? "25vw" : smallToMid ? "20vw" : "15vw"}
               />
            </Grid>
            <Grid container className="home__container content">
               <Grid item xs={10} sm={9} lg={7} className="home__container content-section-buttons">
                  <h1 className="title">
                     Bienvenida / Bienvenido a este espacio diseñado para el acceso a tu cuenta
                     Microsoft.
                  </h1>
                  <Button
                     component={Link}
                     to="/informacion"
                     variant="contained"
                     color="primary"
                     fullWidth
                  >
                     Acceso a mi cuenta
                  </Button>
                  <Button
                     component={Link}
                     to="/restablecer"
                     variant="contained"
                     color="secondary"
                     fullWidth
                  >
                     Restablecer contraseña
                  </Button>
               </Grid>
               <Grid item xs={10} sm={9} lg={7} className="home__container content-login-button">
                  <Button variant="text" color="secondary" fullWidth onClick={() => handleLogin()}>
                     Iniciar sesión con mi cuenta Microsoft
                     <div className="arrow">
                        <div className="head"></div>
                     </div>
                  </Button>
               </Grid>
            </Grid>
         </Grid>
      </Grid>
   );
}
