import React, { useState, useContext } from "react";

// Images
import PasswordIcon from "@mui/icons-material/Password";
// Mui components
import { Box, Button, TextField } from "@mui/material";
// Context
import AppProvider from "../../context/GlobalContext";

//Style components
const headerStyle = { color: "#000000", fontSize: "1.1rem" };
const formBox = {
   borderRadius: "4px",
   boxShadow: "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
   padding: "15px 22px",
};

export default function ResetCodeForm(props) {
   const { changePassword } = useContext(AppProvider);

   const [form, setForm] = useState({
      email: "",
      validateMail: false,
      buttonState: true,
      propError: false,
      messageError: "",
      noMail: "",
      error: false,
      code: "",
      propErrorCode: false,
      messageErrorCode: "",
   });

   /**
    * @description Funcion para manejar los cambios en el formulario
    */
   const handleChange = (targetName, targetValue) => {
      let formCopy = JSON.parse(JSON.stringify(form));

      switch (targetName) {
         case "email":
            // eliminamos los espacios
            targetValue.replace(/\s/g, "");

            formCopy.email = targetValue;

            // Expresion regular para validar que lo ingresado corresponde al formato de email con el dominio @senl.gob.mx
            const regExIsDomainValid = /^[a-zA-Z0-9._+-]+@senl\.gob\.mx/i;

            if (regExIsDomainValid.test(targetValue)) {
               //Si el correo es valido, quitamos los errores y habilitamos el botón
               formCopy.buttonState = false;
               formCopy.buttonError = false;
               formCopy.propError = false;
               formCopy.messageError = "";
            } else {
               // Mostramos el error y deshabilitamos el botón
               formCopy.buttonState = true;
               formCopy.buttonError = true;
               formCopy.propError = true;
               formCopy.messageError = "Ingresa un correo válido";
            }
            break;

         case "code":
            // eliminamos los espacios
            targetValue.replace(/\s/g, "");

            formCopy.code = targetValue;

            if (!targetValue) {
               formCopy.buttonState = true;
               formCopy.buttonError = true;
               formCopy.propErrorCode = true;
               formCopy.messageErrorCode = "Ingresa el código";
            }

            formCopy.buttonState = false;
            formCopy.buttonError = false;
            formCopy.propErrorCode = false;
            formCopy.messageErrorCode = "";
            break;
         default:
            break;
      }

      setForm(formCopy);
   };


   /**
    * @description función para realizar el cambio de contraseña, se valida el correo instuticional y el código recibido
    */
   const resetPassword = async () => {
      let data = {
         email: form.email,
         code: form.code,
      };

      await changePassword(data);
   };

   return (
      <Box
         sx={{
            width: "100%",
            height: "72vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
         }}
      >
         <Box bgcolor="background.paper" style={formBox}>
            <div className="form-container--text">
               <PasswordIcon sx={{ fontSize: 45 }} color={"secondary"} />
               <h2>Ingresar datos</h2>
               <p style={headerStyle}>
                  Ingresa tu correo institucional y el código que recibiste en tu correo
                  electrónico.
               </p>
            </div>

            <TextField
               name="email"
               color="primary"
               label="Ingresa tu correo institucional"
               placeholder=" Ejemplo: correo@onmicrosoft.com"
               fullWidth
               required
               error={form.propError}
               variant="outlined"
               value={form.mail}
               onChange={(event) => handleChange(event.target.name, event.target.value)}
               helperText={form.messageError}
            />
            <TextField
               name="code"
               color="primary"
               label="Ingresa el código"
               fullWidth
               required
               error={form.propErrorCode}
               variant="outlined"
               value={form.code}
               onChange={(event) => handleChange(event.target.name, event.target.value)}
               helperText={form.messageErrorCode}
            />
            <div className="justify-content--end">
               <Button
                  variant="contained"
                  disabled={form.buttonState}
                  size="large"
                  onClick={resetPassword}
               >
                  Enviar
               </Button>
            </div>
         </Box>
      </Box>
   );
}
