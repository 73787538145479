import React, { useState, useContext } from "react";

// Mui components
import { Box, Button, TextField } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
// Context
import AppProvider from "../../context/GlobalContext";

//Style components
const headerStyle = { color: "#000000", fontSize: "1.1rem" };
const formBox = {
   borderRadius: "4px",
   boxShadow: "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
   padding: "15px 22px",
};

export default function AltEmailForm(props) {
   let { handleNext, formData } = props;

   const { addAlternativeEmail, sendResetPasswordMail } = useContext(AppProvider);

   const [form, setForm] = useState({
      altEmail: "",
      validateMail: false,
      buttonState: true,
      propError: false,
      messageError: "",
      error: false,
   });

   /**
    * @description Funcion para manejar los cambios en el formulario
    */
   const handleChange = (targetName, targetValue) => {
      let formCopy = JSON.parse(JSON.stringify(form));

      // quitamos los espacios
      targetValue.replace(/\s/g, "");
      // asignamos el valor ingresado
      formCopy.altEmail = targetValue;

      //Expresión regular para validar si el string es una correo
      const regExIsEmail = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

      //Validamos el email
      if (regExIsEmail.test(targetValue)) {
         // Si el email es valido, cambia los valores para habilitar el botón
         formCopy.buttonState = false;
         formCopy.buttonError = false;
         formCopy.propError = false;
         formCopy.messageError = "";
      } else {
         // Si no es valido, deshabilitamos el botón y mostramos el error
         formCopy.buttonState = true;
         formCopy.buttonError = true;
         formCopy.propError = true;
         formCopy.messageError = "Ingresa un correo válido";
      }

      setForm(formCopy);
   };

   /**
    * @description Función 
    * Añade el email alternativo si no existe ninguno y continúa enviando el correo
    * para el restablecimiento de contraseña
    */
   async function handleSend() {
      let data = {
         email: formData,
         alternativeMail: form.altEmail,
      };

      let response = await addAlternativeEmail(data);
      
      if (response) {
         let res = await sendResetPasswordMail({ email: formData });
         if (res) handleNext(data);
      }
   }

   return (
      <React.Fragment>
         <Box bgcolor="background.paper" style={formBox}>
            <div className="form-container--text">
               <MailOutlineIcon sx={{ fontSize: 45 }} />
               <h2>Correo alternativo</h2>
               <p style={headerStyle}>
                  Proporciona un correo alternativo donde recibirás las instrucciones.
               </p>
            </div>

            <TextField
               color="primary"
               label="Ingresa un correo alternativo"
               placeholder=" Ejemplo: correo@hotmail.com"
               fullWidth
               required
               error={form.propError}
               variant="outlined"
               value={form.altEmail}
               onChange={(event) => handleChange(event.target.name, event.target.value)}
               helperText={form.messageError}
            />
            {/* Button component, display a button with a icon at the end of itself*/}
            <div className="justify-content--end">
               <Button
                  variant="contained"
                  disabled={form.buttonState}
                  size="large"
                  onClick={handleSend}
               >
                  Enviar
               </Button>
            </div>
         </Box>
      </React.Fragment>
   );
}
