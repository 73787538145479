import React, { Fragment, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

// Mui components
import { Button, Box, TextField, MenuItem } from "@mui/material";
// Images
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// Context
import AppProvider from "../../context/GlobalContext";
// Catálogo de errores
import { errorHelper } from "../../config/catalogs";

const errorHelperKeys = errorHelper;
const formErrorsInit = {
   formHasErrors: true,
   formResponseErrors: {
      accountType: true,
      curp: true,
      cct: true,
      registrationNumber: true,
   },
   errorHelperForm: {
      accountType: errorHelperKeys.removeError,
      curp: errorHelperKeys.removeError,
      cct: errorHelperKeys.removeError,
      registrationNumber: errorHelperKeys.removeError,
   },
};

export default function MainForm() {
   const { updateContextAttribute, getDataUser } = useContext(AppProvider);
   const navigate = useNavigate();

   const [formAccount, setFormAccount] = useState({
      accountType: "",
      curp: "",
      cct: "",
      registrationNumber: "",
   });

   const [formErrors, setFormErrors] = useState(formErrorsInit);

   /**
    * @description Funcion para manejar los cambios en el formulario
    */
   const handleChangesForm = async (targetName, targetValue) => {
      let { formHasErrors, formResponseErrors, errorHelperForm } = formErrors;

      let formAccountCopy = JSON.parse(JSON.stringify(formAccount));
      let formHasErrorsCopy = JSON.parse(JSON.stringify(formHasErrors));
      let formResponseErrorsCopy = JSON.parse(JSON.stringify(formResponseErrors));
      let errorHelperFormCopy = JSON.parse(JSON.stringify(errorHelperForm));

      // Buscamos errores según el campo
      switch (targetName) {
         case "accountType":
            formAccountCopy[targetName] = targetValue; // guardamos el campo

            // si el campo está vacío, agregar errores
            if (targetValue === "") {
               formResponseErrorsCopy[targetName] = true;
               errorHelperFormCopy[targetName] = errorHelperKeys.emptyField;
               break;
            }

            // limpiamos los campos
            formAccountCopy["cct"] = "";
            formAccountCopy["registrationNumber"] = "";

            if (targetValue === "student") {
               // si el tipo de cuenta es "alumno", establecemos los errores correspondientes
               formResponseErrorsCopy["registrationNumber"] = true;
               errorHelperFormCopy["registrationNumber"] = errorHelperKeys.removeError;
               formResponseErrorsCopy["cct"] = false;
               errorHelperFormCopy["cct"] = errorHelperKeys.removeError;
            } else {
                // si el tipo de cuenta es "administrativo o docente", establecemos los errores correspondientes
               formResponseErrorsCopy["registrationNumber"] = false;
               errorHelperFormCopy["registrationNumber"] = errorHelperKeys.removeError;
               formResponseErrorsCopy["cct"] = true;
               errorHelperFormCopy["cct"] = errorHelperKeys.removeError;
            }
            //quitamos los errores en el campo
            formResponseErrorsCopy[targetName] = false;
            errorHelperFormCopy[targetName] = errorHelperKeys.removeError;
            break;

         case "curp":
            formAccountCopy[targetName] = targetValue; // guardamos el campo

            // si el campo está vacío, agregar errores
            if (targetValue === "") {
               formResponseErrorsCopy[targetName] = true;
               errorHelperFormCopy[targetName] = errorHelperKeys.emptyField;
               break;
            }
            
            // realizamos una validación a la CURP a través del la expresión regular
            let regEx =
               /^[a-z]{1}[aeiou]{1}[a-z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[hm]{1}(as|bc|bs|cc|cs|ch|cl|cm|df|dg|gt|gr|hg|jc|mc|mn|ms|nt|nl|oc|pl|qt|qr|sp|sl|sr|tc|ts|tl|vz|yn|zs|ne)[b-df-hj-np-tv-z]{3}[0-9a-z]{1}[0-9a-z]{1}$/;
            if (!regEx.test(targetValue.toLowerCase())) {
               // si no es valida la curp marcamos el error
               formResponseErrorsCopy[targetName] = true;
               errorHelperFormCopy[targetName] = errorHelperKeys.notValidCURP;
               break;
            }

            //quitamos los errores
            formResponseErrorsCopy[targetName] = false;
            errorHelperFormCopy[targetName] = errorHelperKeys.removeError;
            break;

         default: // guardamos el campo
            formAccountCopy[targetName] = targetValue;

            // si el campo está vacío, agregar errores
            if (targetValue === "") {
               formResponseErrorsCopy[targetName] = true;
               errorHelperFormCopy[targetName] = errorHelperKeys.emptyField;
               break;
            }

            //quitamos los errores
            formResponseErrorsCopy[targetName] = false;
            errorHelperFormCopy[targetName] = errorHelperKeys.removeError;
            break;
      }
      //iteramos el objeto de errores
      const countErrors = Object.values(formResponseErrorsCopy).reduce((a, item) => a + item, 0);

      formHasErrorsCopy = countErrors > 0 ? true : false;

      setFormAccount(formAccountCopy);
      setFormErrors({
         ...formErrors,
         formHasErrors: formHasErrorsCopy,
         formResponseErrors: formResponseErrorsCopy,
         errorHelperForm: errorHelperFormCopy,
      });
   };

   /**
   * @description Obtenemos los datos de la cuenta asignada
   */
   const getData = async () => {
      let { curp, registrationNumber, cct, accountType } = formAccount;
      let data = { curp, cct, registrationNumber };
      let type = "";

      if (accountType === "student") {
         delete data.cct;
         type = "GetDataStudent";
      } else {
         delete data.registrationNumber;
         type = "GetDataProfessor";
      }

      // consultamos los datos de la cuenta
      let res = await getDataUser(data, type);

      if (res) {
         updateContextAttribute("user", res);
         navigate("/cuenta");
      }
   };

   return (
      <Fragment>
         <Box
            sx={{
               width: "100%",
               height: "72vh",
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
            }}
         >
            <Box bgcolor="background.paper" className="form-container">
               <div className="form-container--text">
                  <AccountCircleIcon sx={{ fontSize: 48 }} />
                  <h2>Acceso a mi cuenta</h2>
                  <p>Para acceder a la información de tu cuenta, completa los siguientes campos.</p>
               </div>
               <TextField
                  select
                  required
                  fullWidth
                  name="accountType"
                  label="Tipo de cuenta"
                  variant="outlined"
                  value={formAccount.accountType}
                  error={formErrors.formResponseErrors.accountType}
                  helperText={formErrors.errorHelperForm.accountType}
                  onChange={(event) => handleChangesForm(event.target.name, event.target.value)}
               >
                  <MenuItem value="student">Alumno</MenuItem>
                  <MenuItem value="professor">Docente</MenuItem>
                  <MenuItem value="administrative">Administrativo</MenuItem>
               </TextField>
               <TextField
                  fullWidth
                  required
                  name="curp"
                  label="CURP"
                  variant="outlined"
                  value={formAccount.curp}
                  error={formErrors.formResponseErrors.curp}
                  helperText={formErrors.errorHelperForm.curp}
                  onChange={(event) => handleChangesForm(event.target.name, event.target.value)}
               />

               {formAccount.accountType === "student" ? (
                  <TextField
                     fullWidth
                     required
                     name="registrationNumber"
                     label="Matrícula"
                     variant="outlined"
                     value={formAccount.registrationNumber}
                     error={formErrors.formResponseErrors.registrationNumber}
                     helperText={formErrors.errorHelperForm.registrationNumber}
                     onChange={(event) => handleChangesForm(event.target.name, event.target.value)}
                  />
               ) : (
                  <TextField
                     fullWidth
                     required
                     name="cct"
                     label="CCT"
                     variant="outlined"
                     value={formAccount.cct}
                     error={formErrors.formResponseErrors.cct}
                     helperText={formErrors.errorHelperForm.cct}
                     onChange={(event) => handleChangesForm(event.target.name, event.target.value)}
                  />
               )}
               <div className="justify-content--end">
                  <Button
                     variant="contained"
                     type="submit"
                     color="primary"
                     size="large"
                     disabled={formErrors.formHasErrors}
                     onClick={getData}
                  >
                     Enviar
                  </Button>
               </div>
            </Box>
         </Box>
      </Fragment>
   );
}
