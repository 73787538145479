import React, { Fragment, useContext } from "react";

// Mui components
import { Box } from "@mui/material";
// Images
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// Context
import AppProvider from "../context/GlobalContext";
// styles
import "./styles/AccountForm.css";

export default function MyAccount() {
   const { user } = useContext(AppProvider);

   return (
      <Fragment>
         <Box
            sx={{
               height: "72vh",
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
            }}
         >
            <Box
               bgcolor="background.paper"
               className="form-container"
               sx={{
                  width: "50%",
               }}
            >
               <div className="form-container--text">
                  <AccountCircleIcon sx={{ fontSize: 48 }} />
                  <h2>Información de mi cuenta</h2>
                  <p></p>
               </div>
               <div className="account-data">
                  <p>
                     <span>Nombre: </span> {user.name}
                  </p>
                  <p>
                     <span>CURP: </span> {user.curp}
                  </p>
                  <p>
                     <span>CCT: </span> {user.cct}
                  </p>
                  <p>
                     <span>Email: </span> {user.email}
                  </p>
                  <p>
                     <span> Rol:</span> {user.rol}
                  </p>
               </div>
            </Box>
         </Box>
      </Fragment>
   );
}
