import React, { useContext, useState, useEffect, useCallback } from "react";

// Mui components
import { Grid, Paper } from "@mui/material";
import {
   DataGrid,
   GridToolbarExport,
   GridToolbarContainer,
   GridToolbarQuickFilter,
} from "@mui/x-data-grid";
// catalogo de traducciones
import { localeText } from "../config/catalogs";
// context
import AppProvider from "../context/GlobalContext";

const columns = [
   { field: "curp", headerName: "CURP", width: 220 },
   { field: "name", headerName: "Nombre", width: 280 },
   { field: "email", headerName: "Correo electrónico", width: 250 },
   { field: "cct", headerName: "CCT", width: 120 },
   { field: "rol", headerName: "Rol", width: 150 },
];

const rolesPrincipal = ["Director o Encargado", "Director o Encargado Secundaria (J)"];
const rolesSupervisor = ["Inspector o Supervisor Escolar"];

/**
 * @description se personaliza la barra de herramientas, permitiendo el filtrado y exportación del contenido de la tabla
 * @returns componente Toolbar
 */
function CustomToolbar() {
   return (
      <GridToolbarContainer
         sx={{
            p: 0.9,
            justifyContent: "space-between",
         }}
      >
         <GridToolbarQuickFilter
            quickFilterParser={(searchInput) =>
               searchInput
                  .split(",")
                  .map((value) => value.trim())
                  .filter((value) => value !== "")
            }
         />
         <GridToolbarExport />
      </GridToolbarContainer>
   );
}

export default function InstitutionalAccounts() {
   const [loader, setLoader] = useState(false);
   const [data, setData] = useState([{}]);
   const { user, getInstitutionAccounts } = useContext(AppProvider);

   /**
    * @description función para agregar ids a los datos que serán mostrados
    * @param {*} array 
    */
   function addIds(array) {
      if (array && array.length > 0) {
         for (let i = 0; i < array.length; i++) {
            array[i].id = i + 1;
         }
         return array;
      }
   }

   /**
    * @description función para obtener la información de las cuentas correspondientes a la escuela y usuario
    */
   const fetchAccounts = useCallback(async () => {
      let data = {
         email: user.email,
         rol: user.rol,
      };

      let isPrincipal = rolesPrincipal.includes(user.rol);
      let isSupervisor = rolesSupervisor.includes(user.rol);
      // dependiendo del tipo de rol se selecciona el tipo de petición a realizar
      let type = isPrincipal ? "GetDataDirector" : isSupervisor ? "GetDataSupervisor" : null;
      if (type) {
         // obtenemos las cuentas institucionales
         let res = await getInstitutionAccounts(data, type);
         if (res) setData(res);
      }
      setLoader(false);
   }, [getInstitutionAccounts, user]);

   useEffect(() => {
      if (!user?.email) return;

      fetchAccounts();
   }, [user, fetchAccounts]);

   return (
      <Grid container justifyContent={"center"}>
         <Grid item xs={11} md={11}>
            <h2>Cuentas institucionales</h2>
            <Paper>
               <div style={{ height: "100vh", width: "100%" }}>
                  <DataGrid
                     rows={addIds(data)}
                     columns={columns}
                     components={{ Toolbar: CustomToolbar }}
                     localeText={localeText}
                     pageSize={50}
                     rowsPerPageOptions={[50]}
                     checkboxSelection={true}
                     disableExtendRowFullWidth={false}
                     loading={loader}
                  />
               </div>
            </Paper>
         </Grid>
      </Grid>
   );
}
