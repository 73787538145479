//Mui
import { createTheme } from "@mui/material/styles";
import { esES } from "@mui/material/locale";
// Configuración
import config from "../config/configuration";

const theme = createTheme(
   {
      palette: {
         background: {
            default: config.background_color,
            paper: "#fff",
         },
         primary: {
            main: "#00Aca6",
            light: "#9CDBD9",
            dark: "#00737e",
            contrastText: "#fff",
         },
         secondary: {
            main: "#009087",
            light: "#009eb2",
            dark: "#007f76",
            contrastText: "#fff",
         },
         golden: {
            main: "#AF8C55",
         },
         dark: {
            main: "#15212F",
         },
         white: {
            main: "#fff",
         },
      },
      typography: {
         button: {
            textTransform: "none",
         },
      },
      components: {
         // Name of the component
         MuiButton: {
            styleOverrides: {
               root: { fontSize: "0.9rem" },
            },
         },
      },
   },
   esES
);

export default theme;
