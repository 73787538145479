import React, { createContext } from "react";

// Funciones
import { authActions, passwordResetOperations } from "./Functions";
// Components
import LoadingDialog from "../components/common/LoadingDialog";
import CustomAlert from "../components/common/CustomAlert";

//External Libraries
import Swal from "sweetalert2"; //https://sweetalert2.github.io
// Microsoft Authentication Library for React
import { MsalContext } from "@azure/msal-react"; //https://www.npmjs.com/package/@azure/msal-react

//Context 
const AppContext = createContext("AppContext");

export class AppProvider extends React.Component {
   static contextType = MsalContext;
   // Context state
   state = {
      loader: false,
      cancelToken: undefined,
      contextReady: false,
      userLoggedIn: false,
      // alerts
      alert: {
         open: false,
         severity: "", //success, error, warning, info
         message: "",
      },
      loadingDialog: false,
      loadingMessage: "",
      isAuthenticatedMSAL: false,
      user: {},
   };

   componentDidMount() {
      const { getAuthTokenValidation, performURLaction } = this;
      getAuthTokenValidation();
      performURLaction();
   }

   performURLaction = () => {
      const { confirmRole, resendLink } = this;
      let url = new URL(document.location);
      let searchParams = new URLSearchParams(url.search);
      let token = searchParams.get("token");
      let action = searchParams.get("action");
      switch (action) {
         case "ConfirmRole":
            confirmRole(token);
            break;
         case "ResendLink":
            resendLink(token);
            break;
         default:
            break;
      }
   };

   resendLink = async (email) => {
      const { updateContextAttribute } = this;
      updateContextAttribute("loadingDialog", true);
      updateContextAttribute("loadingMessage", "Enviando correo...");
      let res = await authActions(null, `ResendLink&email=${email}`);
      updateContextAttribute("loadingDialog", false);
      updateContextAttribute("loadingMessage", "");
      let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //-> si no hay codigo de respuesta lo mandamos al default
      switch (responseCode) {
         case 0:
            Swal.fire({
               icon: "success",
               title: "Correo enviado",
               text: "Se ha enviado un correo a la cuenta indicada",
               timer: 3000,
            });
            break;
         default:
            await Swal.fire({
               icon: "error",
               title: "Hubo un error al enviar el correo.",
               text: "Inténtalo más tarde.",
            });
            break;
      }
   };

   clearContext = () => {
      this.setState({
         loader: false,
         cancelToken: undefined,
         contextReady: false,
         userLoggedIn: false,
         loadingDialog: false,
         loadingMessage: "",
         //State for user feedback
         alert: {
            open: false,
            severity: "", //success, error, warning, info
            message: "",
         },
         isAuthenticatedMSAL: false,
         user: {},
      });
   };

   /**
    * @description actualización del estado
    * @param {*} key 
    * @param {*} value 
    */
   updateContextAttribute = (key, value) => {
      this.setState({
         [key]: value,
      });
   };

   /**
    * @description Función para el cierre de sesión
    * @param {*} noConfirm 
    * @returns 
    */
   logOutUser = async (noConfirm) => {
      const { instance } = this.props;
      const { clearContext } = this;
      if (!noConfirm) {
         let result = await Swal.fire({
            text: "¿Deseas cerrar sesión?",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: `No`,
            confirmButtonColor: "#00Aca6",
            denyButtonColor: "#00737e",
         });
         //Cancelamos el cierre de sesión
         if (result.isDenied || result.isDismissed) {
            return;
         }
      }
      // limpiamos el localstorage y context
      localStorage.clear();
      clearContext();

      instance.logoutRedirect({
         mainWindowRedirectUri: "/", // redirecciona después del cierre de sesión
      });
   };

   /**
    * @description función para obtener los datos del usuario que inicia sesión
    * @param {*} email 
    */
   fetchUserData = async (email) => {
      //si existe el token significa que el user esta loggeado
      this.setState({
         loadingDialog: true,
         loadingMessage: "Estamos realizando la conexión con el servicio.",
      });

      let res = await passwordResetOperations({ email }, "GetDataProfile");
      let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; 

      const stateData = {
         alert: {
            open: false,
            severity: "", //success, error, warning, info
            message: "",
         },
         loadingDialog: false,
         loadingMessage: "",
      };

      // obtenemos la información del usuario a partir del token
      switch (responseCode) {
         case 0:
            let currentUser = res.data.data;

            localStorage.setItem("user", JSON.stringify(currentUser));
            let contextReady = true;
            this.setState({
               user: currentUser,
               userLoggedIn: true,
               contextReady,
               loadingDialog: false,
               loadingMessage: "",
            });
            break;
         case 2:
            await Swal.fire({
               icon: "error",
               title: "Acceso denegado",
               text: "No cuentas con los permisos para entrar al portal.",
               confirmButtonColor: "#00Aca6",
               allowOutsideClick: false,
            });
            this.setState(stateData);
            this.logOutUser(true);
            break;
         case 3:
            await Swal.fire({
               icon: "error",
               title: "Tu sesión ha caducado.",
               text: "Inicia sesión de nuevo.",
               confirmButtonColor: "#00Aca6",
               allowOutsideClick: false,
            });
            this.setState(stateData);
            this.logOutUser(true);
            break;
         default:
            stateData.alert.open = true;
            stateData.alert.severity = "error";
            stateData.alert.message =
               "Ocurrió un error al obtener la información de la cuenta, Inténtalo más tarde.";
            this.setState(stateData);
            break;
      }
   };

   /**
    * @description validación del token de autenticación
    */
   getAuthTokenValidation = async () => {
      const { fetchUserData } = this;
      //buscamos si el token se guardo en local/session
      let token = localStorage.getItem("token");
      let userData = JSON.parse(localStorage.getItem("userData"));
      if (token && userData) {
         let email = userData.username;
         fetchUserData(email);
      }
   };

   /**
    * @description
    * @param {*} storageValuesToGet 
    * @returns 
    */
   getStorageData = (storageValuesToGet) => {
      //preguntamos si estamos en el local o session storage
      let storageData = {};
      if (!storageValuesToGet || storageValuesToGet.length === 0)
         throw new Error("Can't get empty values from storage");

      //Buscamos la info en el local storage
      for (let index = 0; index < storageValuesToGet.length; index++) {
         const keyToGet = storageValuesToGet[index];
         let storageDataToSave = localStorage.getItem(keyToGet);
         //Regresamos la info pedida del local storage
         try {
            storageData[keyToGet] = JSON.parse(storageDataToSave);
         } catch (error) {
            storageData[keyToGet] = storageDataToSave;
         }
      }
      return storageData;
   };

   /**
    * @description función para enviar el correo el código para continuar el restablecimiento de contraseña
    * @param {*} data 
    * @returns 
    */
   sendResetPasswordMail = async (data) => {
      this.setState({
         loadingDialog: true,
         loadingMessage: "Enviando correo...",
      });

      const stateData = {
         alert: {
            open: false,
            severity: "", //success, error, warning, info
            message: "",
         },
         loadingDialog: false,
         loadingMessage: "",
      };

      try {
         let res = await passwordResetOperations(data, "PasswordReset");
         let code = parseInt(res?.data?.code) ?? 99;

         switch (code) {
            case 0:
               stateData.alert.open = true;
               stateData.alert.severity = "success";
               stateData.alert.message = "Se ha enviado el correo, revise su bandeja de entrada.";
               this.setState(stateData);
               return true;
            case 1:
               await Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Ocurrió un error al enviar el correo. Inténtalo más tarde.",
                  confirmButtonColor: "#00Aca6",
                  allowOutsideClick: false,
               });
               this.setState(stateData);

               break;
            case 2:
               await Swal.fire({
                  icon: "error",
                  title: "No se encontró un correo para enviar el mensaje",
                  text: "No olvides agregar un correo alternativo para recibir las instrucciones.",
                  confirmButtonColor: "#00Aca6",
                  allowOutsideClick: false,
               });
               this.setState(stateData);

               break;
            default:
               await Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Ocurrió un error al enviar el correo. Inténtalo más tarde.",
                  confirmButtonColor: "#00Aca6",
                  allowOutsideClick: false,
               });
               this.setState(stateData);

               break;
         }
      } catch (error) {
         stateData.alert.open = true;
         stateData.alert.severity = "error";
         stateData.alert.message = "Ocurrió un error al enviar el correo.";
         this.setState(stateData);
      }
   };

   /**
    * @description función para realizar el restablecimiento de contraseña y enviar un correo con una contraseña temporal
    * @param {*} data 
    */
   changePassword = async (data) => {
      this.setState({
         loadingDialog: true,
         loadingMessage: "Procesando información...",
      });

      const stateData = {
         alert: {
            open: false,
            severity: "", //success, error, warning, info
            message: "",
         },
         loadingDialog: false,
         loadingMessage: "",
      };

      try {
         let res = await passwordResetOperations(data, "ChangePassword");
         let code = parseInt(res?.data?.code) ?? 99;

         switch (code) {
            case 3:
               let result = await Swal.fire({
                  icon: "success",
                  title: "Se restableció la contraseña",
                  text: "Se ha enviado un correo con la contraseña temporal, revise su bandeja de entrada.",
                  confirmButtonColor: "#00Aca6",
                  allowOutsideClick: false,
               });
               this.setState(stateData);

               if (result) {
                  window.location.href = "/";
               }
               break;
            case 4:
               await Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Ocurrió un error al restablecer la contraseña. Inténtalo más tarde.",
                  confirmButtonColor: "#00Aca6",
                  allowOutsideClick: false,
               });
               this.setState(stateData);

               break;
            case 5:
               await Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Los datos proporcionados son incorrectos o no existen, favor de verificarlos. ",
                  confirmButtonColor: "#00Aca6",
                  allowOutsideClick: false,
               });
               this.setState(stateData);

               break;
            default:
               await Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Ocurrió un error al enviar el correo. Inténtalo más tarde.",
                  confirmButtonColor: "#00Aca6",
                  allowOutsideClick: false,
               });
               this.setState(stateData);

               break;
         }
      } catch (error) {
         stateData.alert.open = true;
         stateData.alert.severity = "error";
         stateData.alert.message = "Ocurrió un error al enviar el correo.";
         this.setState(stateData);
      }
   };

   /**
    * @description función prara añadir el correo alternativo
    * @param {*} data 
    * @returns 
    */
   addAlternativeEmail = async (data) => {
      this.setState({
         loadingDialog: true,
         loadingMessage: "Agregando correo...",
      });

      const stateData = {
         alert: {
            open: false,
            severity: "", //success, error, warning, info
            message: "",
         },
         loadingDialog: false,
         loadingMessage: "",
      };

      try {
         let res = await passwordResetOperations(data, "AddAlternativeEmail");
         let code = parseInt(res?.data?.code) ?? 99;

         switch (code) {
            case 2:
               this.setState(stateData);
               return true;

            case 4:
               Swal.fire({
                  icon: "info",
                  title: "Esta cuenta ya contiene un correo alternativo, previamente registrado.",
                  html: "Se enviará el mensaje al correo previamente proporcionado. <br /><br /> En caso de desear cambiar su correo alternativo contactate al <a href='mailto:someone@example.com'>administrador de correos</a>.",
                  background: "#fff",
                  width: 600,
                  confirmButtonColor: "#00Aca6",
                  allowOutsideClick: false,
                });
               this.setState(stateData);

               return true;

            case 3:
            default:
               stateData.alert.open = true;
               stateData.alert.severity = "error";
               stateData.alert.message =
                  "Ocurrió un error al guardar el correo alternativo. Inténtalo más tarde.";
               this.setState(stateData);
               break;
         }
      } catch (error) {
         stateData.alert.open = true;
         stateData.alert.severity = "error";
         stateData.alert.message = "Ocurrió un error al guardar el correo alternativo.";
         this.setState(stateData);
      }
   };

   /**
    * @description Obtener datos para usuarios profesor o alumno
    * @param {*} data
    * @param {*} type
    * @returns
    */
   getDataUser = async (data, type) => {
      this.setState({
         loadingDialog: true,
         loadingMessage: "Obteniendo información...",
      });

      const stateData = {
         alert: {
            open: false,
            severity: "", //success, error, warning, info
            message: "",
         },
         loadingDialog: false,
         loadingMessage: "",
      };

      try {
         let res = await passwordResetOperations(data, type);
         let code = parseInt(res?.data?.code) ?? 99;

         switch (code) {
            case 0:
               let currentUser;
               localStorage.setItem("user", JSON.stringify(currentUser));
               stateData.user = currentUser;
               this.setState(stateData);
               return res.data.data;
            case 1:
               stateData.alert.open = true;
               stateData.alert.severity = "warning";
               stateData.alert.message =
                  "Ocurrió un error al obtener la información de la cuenta, Inténtalo más tarde.";
               this.setState(stateData);

               break;
            case 2:
            default:
               stateData.alert.open = true;
               stateData.alert.severity = "error";
               stateData.alert.message = "Ocurrió un error al obtener la información de la cuenta.";
               this.setState(stateData);
               break;
         }
      } catch (error) {
         stateData.alert.open = true;
         stateData.alert.severity = "error";
         stateData.alert.message = "Ocurrió un error al obtener la información de la cuenta";
         this.setState(stateData);
      }
   };

   /**
    * @description función para obtener el correo alternativo enmascarado
    * @param {*} data 
    * @returns 
    */
   getAlternativeEmail = async (data) => {
      this.setState({
         loadingDialog: true,
         loadingMessage: "Buscando correo...",
      });
      const stateData = {
         alert: {
            open: false,
            severity: "", //success, error, warning, info
            message: "",
         },
         loadingDialog: false,
         loadingMessage: "",
      };

      try {
         let res = await passwordResetOperations(data, "GetAlternativeEmail");
         let code = parseInt(res?.data?.code) ?? 99;

         switch (code) {
            case 0:
               this.setState(stateData);
               return {code: true}
            case 1:
               this.setState(stateData);
               return {code: true, email: res.data.data}
            default:
               stateData.alert.open = true;
               stateData.alert.severity = "error";
               stateData.alert.message = "Ocurrió un error al validar el correo. Inténtalo más tarde.";
               this.setState(stateData);
               break;
         }
      } catch (error) {
         stateData.alert.open = true;
         stateData.alert.severity = "error";
         stateData.alert.message = "Ocurrió un error un error al validar el correo.";
         this.setState(stateData);
      }
   };

   /**
    * @description función para obtener las cuentas institucionales
    * @param {*} data 
    * @param {*} type 
    * @returns 
    */
   getInstitutionAccounts = async (data, type) => {
      this.setState({
         loadingDialog: true,
         loadingMessage: "Obteniendo información...",
      });

      const stateData = {
         alert: {
            open: false,
            severity: "", //success, error, warning, info
            message: "",
         },
         loadingDialog: false,
         loadingMessage: "",
      };

      try {
         let res = await passwordResetOperations(data, type);
         let code = parseInt(res?.data?.code) ?? 99;

         switch (code) {
            case 0:
               stateData.alert.open = true;
               stateData.alert.severity = "success";
               stateData.alert.message = "Se ha obtenido la información correctamente.";
               this.setState(stateData);

               return res.data.data;
            case 1:
               stateData.alert.open = true;
               stateData.alert.severity = "warning";
               stateData.alert.message =
                  "Ocurrió un error al obtener las cuentas institucionales. Inténtalo más tarde.";
               this.setState(stateData);

               break;
            case 2:
            default:
               stateData.alert.open = true;
               stateData.alert.severity = "error";
               stateData.alert.message =
                  "Ocurrió un error al obtener la información de las cuentas. Inténtalo más tarde.";
               this.setState(stateData);

               break;
         }
      } catch (error) {
         stateData.alert.open = true;
         stateData.alert.severity = "error";
         stateData.alert.message = "Ocurrió un error al obtener la información de las cuentas.";
         this.setState(stateData);
      }
   };

   //Cerrar alerta
   handleCloseAlert = (reason) => {
      if (reason === "clickaway") {
         return;
      }
      this.setState({
         alert: {
            open: false,
            message: "",
         },
      });
   };

   render() {
      const { children } = this.props;
      const { alert, loadingDialog, loadingMessage } = this.state;
      const { handleCloseAlert } = this;

      return (
         <React.Fragment>
            <CustomAlert
               open={alert.open}
               severity={alert.severity}
               message={alert.message}
               handleCloseAlert={() => handleCloseAlert()}
            />
            <LoadingDialog open={loadingDialog} loadingMessage={loadingMessage} />
            <AppContext.Provider
               value={{
                  ...this,
                  ...this.state,
               }}
            >
               {children}
            </AppContext.Provider>
         </React.Fragment>
      );
   }
}

export default AppContext;
