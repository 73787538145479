import React, { Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";

// Mui components
import { Button, AppBar, Card, CardMedia, Toolbar } from "@mui/material";
// Images
import logo from "../../assets/images/SENL.png";
import WestIcon from "@mui/icons-material/West";
// Styles
import "./styles/PublicNavBar.css";

export default function PublicNavBar() {
   return (
      <Fragment>
         <AppBar position="static" color="inherit">
            {/* Card component, contiene la imagen de la institución */}
            <Card className="card-container">
               <CardMedia
                  component="img"
                  alt="Imagen de portada"
                  src={logo}
                  title="Imagen de portada"
                  className="card-media"
               />
            </Card>
            <Toolbar>
               <Button component={RouterLink} to="/" startIcon={<WestIcon />}>
                  Regresar
               </Button>
            </Toolbar>
         </AppBar>
      </Fragment>
   );
}
