import React, { Fragment, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";

// Mui components
import { Button, AppBar, Toolbar, Divider } from "@mui/material";
// Images
import logo from "../../assets/images/SENL.png";
// Microsoft Authentication Library for React
import { AuthenticatedTemplate } from "@azure/msal-react"; //https://www.npmjs.com/package/@azure/msal-react
// Context
import AppProvider from "../../context/GlobalContext";
// Styles
import "./styles/PrivateNavBar.css";

export default function PrivateNavBar() {
   const { logOutUser } = useContext(AppProvider);

   /**
    * @description llamado al popup y función para el cierre de sesión
    */
   const handleLogoutPopup = async () => {
      logOutUser();
   };

   return (
      <Fragment>
         <AppBar position="static" color="primary" className="nav-bar">
            <div className="nav-bar__logo container">
               <img src={logo} alt="Logo" className="nav-bar__logo image" />
            </div>
            <Toolbar className="nav-bar__options">
               <div className="main-buttons">
                  <Button component={RouterLink} to="/">
                     Cuentas institucionales
                  </Button>
                  <Divider>|</Divider>
                  <Button component={RouterLink} to="/mi-cuenta">
                     Mi cuenta
                  </Button>
               </div>
               <AuthenticatedTemplate>
                  <Button onClick={handleLogoutPopup}>Cerrar sesión</Button>
               </AuthenticatedTemplate>
            </Toolbar>
         </AppBar>
      </Fragment>
   );
}
