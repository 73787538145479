export const errorHelper = {
   removeError: "",
   emptyField: "* El campo no puede estar vacío.",
   emptyTable: "* La tabla no puede estar vacía.",
   authApiError: "* Hubo un error al ingresar, intenta más tarde",
   errorInStorage: "* No se pudo obtener la información de la suscripción, intenta más tarde.",
   notEmail: "* El correo ingresado no es válido.",
   notValidDomain: "* Únicamente se aceptan correos de estas instituciones: ",
   notValidCURP: "* El dato no coincide con el formato de CURP.",
   tableHasNoAdmin: "* La suscripción debe contener al menos 1 administrador.",
};

export const localeText = {
   //
   // Root
   noRowsLabel: "No hay filas",
   noResultsOverlayLabel: "No se encontraron resultados.",
   // Columns selector toolbar button text
   toolbarColumns: "Columnas",
   toolbarColumnsLabel: "Seleccionar columnas",
   // Quick filter toolbar field
   toolbarQuickFilterPlaceholder: "Buscar...",
   toolbarQuickFilterLabel: "Buscar",
   toolbarQuickFilterDeleteIconLabel: "Limpiar",
   // Export selector toolbar button text
   toolbarExport: "Exportar",
   toolbarExportLabel: "Exportar",
   toolbarExportCSV: "Descargar como CSV",
   toolbarExportPrint: "Imprimir",
   // Column menu text
   columnMenuLabel: "Menú",
   columnMenuShowColumns: "Mostrar columnas",
   columnMenuFilter: "Filtrar",
   columnMenuHideColumn: "Esconder columna",
   columnMenuUnsort: "Desordenar",
   columnMenuSortAsc: "Ordenar ASC",
   columnMenuSortDesc: "Ordenar DESC",
   // Rows selected footer text
   footerRowSelected: (count) =>
      count !== 1
         ? `${count.toLocaleString()} filas seleccionadas`
         : `${count.toLocaleString()} fila seleccionada`,

   // Columns panel text
   columnsPanelTextFieldLabel: "Buscar columna",
   columnsPanelTextFieldPlaceholder: "Nombre de la columna",
   columnsPanelDragIconLabel: "Reordenar columna",
   columnsPanelShowAllButton: "Mostrar todo",
   columnsPanelHideAllButton: "Ocultar todo",
   // Filter panel text
   filterPanelAddFilter: "Agregar filtro",
   filterPanelDeleteIconLabel: "Borrar",
   filterPanelLogicOperator: "Operador lógico",
   filterPanelOperator: "Operador",
   filterPanelOperatorAnd: "Y",
   filterPanelOperatorOr: "O",
   filterPanelColumns: "Columnas",
   filterPanelInputLabel: "Valor",
   filterPanelInputPlaceholder: "Filtrar valor",

   // Filter operators text
   filterOperatorContains: "contiene",
   filterOperatorEquals: "igual que",
   filterOperatorStartsWith: "comienza con",
   filterOperatorEndsWith: "termina con",
   filterOperatorIs: "es",
   filterOperatorNot: "no es",
   filterOperatorAfter: "está después de",
   filterOperatorOnOrAfter: "está en o después de",
   filterOperatorBefore: "está antes de",
   filterOperatorOnOrBefore: "está en o antes de",
   filterOperatorIsEmpty: "está vacío",
   filterOperatorIsNotEmpty: "no está vacío",
   filterOperatorIsAnyOf: "es cualquiera de",

   // Filter values text
   filterValueAny: "alguno",
   filterValueTrue: "verdadero",
   filterValueFalse: "false",
};
