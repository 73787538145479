import React from "react";
// Mui components
import { Box, Button } from "@mui/material";
// Images
import MailOutlineIcon from "@mui/icons-material/MailOutline";
//Style components
const headerStyle = { color: "#000000", fontSize: "1.1rem" };
const formBox = {
   borderRadius: "4px",
   boxShadow: "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
   padding: "15px 22px",
};

export default function EmailSended(props) {
   let { handleNext, handleStart, formData } = props;

   /**
    * @description función para enmascarar el correo alternativo
    * @param {*} email 
    * @returns email enmascarado
    */
   const maskEmail = (email) => {
      let arr = email.split("@");
      let censorWord = arr[0][0] + "*".repeat(arr[0].length - 2) + arr[0].slice(-1);
      return censorWord + "@" + arr[1];
   };

   return (
      <div>
         <Box bgcolor="background.paper" style={formBox}>
            <div className="form-container--text">
               <MailOutlineIcon sx={{ fontSize: 45 }} color={"secondary"} />
               <h2>Revisa tu correo electrónico</h2>

               <p style={headerStyle}>
                  Te hemos enviado las instrucciones para que puedas restaurar tu contraseña a la
                  cuenta de correo alternativa. <br />
                  { formData.alternativeMail && 
                     <b>{maskEmail(formData.alternativeMail)}</b>
                  }
                  <br />
                  <br />
                  Recuerda revisar tu bandeja de correos no deseados.
               </p>
            </div>

            <div style={{ marginLeft: "20px", marginRight: "20px" }}>
               <Button fullWidth variant="contained" size="large" onClick={handleNext}>
                  Recibí un código
               </Button>

               <Button fullWidth variant="text" size="large" onClick={handleStart}>
                  ¿No recibiste el correo? Haz click aquí
               </Button>
            </div>
         </Box>
      </div>
   );
}
