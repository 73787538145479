import React, { Fragment, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Mui components
import { Box } from "@mui/material";
// Images
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PublicNavBar from "../components/navBar/PublicNavBar";

// Context
import AppProvider from "../context/GlobalContext";

// styles
import "./styles/AccountForm.css";
// librerias externas
import Swal from "sweetalert2"; //https://sweetalert2.github.io

export default function Account(props) {
   const { user } = useContext(AppProvider);
   const navigate = useNavigate();

   /**
    * @desc mostrará el mensaje y realizará la redirección si no se cuenta con el correo del usuario
    */
   async function showMessage() {
      await Swal.fire({
         icon: "error",
         title: "No hay información",
         text: "Serás redirigido al formulario de información de mi cuenta.",
      });
      navigate("/informacion");
   }

   useEffect(() => {
      if (!user?.email) {
         showMessage();
      }
   }, [user]);

   return (
      <Fragment>
         <PublicNavBar />

         <Box
            sx={{
               height: "72vh",
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
            }}
         >
            <Box
               bgcolor="background.paper"
               className="form-container"
               sx={{
                  width: "50%",
               }}
            >
               <div className="form-container--text">
                  <AccountCircleIcon sx={{ fontSize: 48 }} />
                  <h2>Información de mi cuenta</h2>
                  <p></p>
               </div>
               <div className="account-data">
                  <p>
                     <span>Email: </span>
                     {user.email}
                  </p>
                  <p>
                     <span>Nombre: </span>
                     {user.name}
                  </p>
                  <p>
                     <span>CURP: </span>
                     {user.curp}
                  </p>

                  {user.rol ? (
                     <Fragment>
                        <p>
                           <span>CCT: </span>
                           {user.cct || " N/A"}
                        </p>
                        <p>
                           <span>Nivel educativo: </span> {user["nivel educativo"] || " N/A"}
                        </p>
                        <p>
                           <span>Rol: </span> {user.rol || " N/A"}
                        </p>
                     </Fragment>
                  ) : (
                     <Fragment>
                        <p>
                           <span>Matricula: </span>
                           {user.matricula || " N/A"}
                        </p>
                        <p>
                           <span>Nivel educativo: </span>
                           {user["Nivel Educativo"]}
                        </p>
                        <p>
                           <span>Grado: </span>
                           {user["Grado"]}
                        </p>
                     </Fragment>
                  )}
               </div>
            </Box>
         </Box>
      </Fragment>
   );
}
