import Axios from "axios";

export { authActions, passwordResetOperations };

/**
 * @desc Funciones relacionadas con acciones en la tabla de usuarios
 * @param {Object} data
 * @param {string} type
 * @returns
 */
async function authActions(data, type) {
   if (!type) throw new Error("You must specify an type for this function ");

   try {
      let res = await Axios({
         url: `${process.env.REACT_APP_AUTH_ACTIONS}&type=${type}`,
         data: JSON.stringify(data, null, 2),
         method: "POST",
         timeout: 3000000,
         headers: {
            Authorization: process.env.REACT_APP_AUTHORIZATION,
            "Content-Type": "application/json",
         },
      });
      // console.log("Response in authActions", res);
      return res;
   } catch (error) {
      console.error("Catch error in authActions", error);
      return error;
   }
}

/**
 * @description funciones relacionadas con el restablecimiento de contraseña e información de la cuenta
 * @param {*} data - object, datos del usuario
 * @param {*} type - tipo de operación a realizar 
 * @returns 
 */
async function passwordResetOperations(data, type) {
   if (!type) throw new Error("You must specify an type for this function ");

   try {
      let res = await Axios({
         url: `${process.env.REACT_APP_ENDPOINT_WS}==&type=${type}`,
         data: JSON.stringify(data, null, 2),
         method: "POST",
         timeout: 3000000,
         headers: {
            "Content-Type": "application/json",
         },
      });
      // console.log("Response ", res);
      return res;
   } catch (error) {
      console.error("Catch error ", error);
      return error;
   }
}
